<template>
    <div style="margin-top: 10%">
        <img style="width: 200px;" class="d-block m-auto" src="../assets/logo.png" alt="">
        <p class="text-center mt-2 text-sm text-muted">Nền tảng kết nối đến ứng viên dành cho Nhà tuyển dụng</p>
        <div class="text-center mt-4 font-weight-light">
            <div v-if="isLoading">
                <loading></loading>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .btn-success {
        background: #6fbe44;
        border-radius: 25px;
        border: 1px solid #6fbe44;
        padding: 7px 15px;
    }
    .text-sm {
        font-size: 14px;
    }
</style>
<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading';
export default {
    data() {
        return {
            isLoading: false
        }
    },
    components: {
        Loading
    },
    computed: {
        ...mapState('auth', {
            user: state => state.user,
            isLogined: state => state.isLogined
        }),
        authRedirect() {
            let rootUrl = window.location.href;
            return `${process.env.VUE_APP_EMPLOYER_V2_URL}/auth/authorize?callback_url=${rootUrl}&client_id=connect.topcv.vn`
        }
    },
    async created() {
      console.log(localStorage)
        if (localStorage.getItem('employer_sso_token')) {
            await this.authenticate();
        }
        window.location.href = this.authRedirect;
    },
    methods: {
        async authenticate() {
            this.isLoading = true;
            await this.$store.dispatch('auth/authenticate');
            this.isLoading = false;
        },
    }
}
</script>